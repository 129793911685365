@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f6f8;
}

.background-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@import './basic/color.scss';

@import './components/index.scss';