.form-field {
    input {
        padding: 5px;
        &:disabled {
            opacity: 1;
            -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
        }
    }
    &--title {
        font-size: 1rem;
    }
}
