.document-list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-left: 16px;
    margin-bottom: 8px;
    &--status {
        margin-top: 5px;
        margin-right: 10px;
    }
    &--filename {
        word-wrap: break-word;
    }
    &--icon {
        margin-top: 1px;
        margin-right: 5px;
    }
}