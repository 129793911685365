.background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiPaper-root {
        border-radius: 16px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .MuiTypography-h4 {
        white-space: nowrap;
    }

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        background-color: rgba(255, 255, 255, 0.8);
        width: 100vw;
        height: 100vh;
    }
}

.MuiDivider-root {
    &.MuiBox-root {
        margin-top: 10px;
    }
}

@import 'btn.scss';
@import 'form.scss';
@import 'list.scss';
@import 'sidebar.scss';

.config-box {
    margin-left: 10px;
    &--btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .btn {
            width: calc(50% - 2px);
        }
    }
    &--title {
        margin: 15px 0;
    }
    &--content {
        margin-top: 15px;
        .btn {
            width: 100%;
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.75;
            text-transform: uppercase;
            min-width: 64px;
            padding: 6px 8px;
            border-radius: 4px;
            &-orange {
                color: black;
                background-color: $prime-color;
            }
            &:hover {
                cursor: pointer;
                color: white;
                background-color: $hover-color;
            }
            &:disabled {
                color: rgba(0, 0, 0, 0.26);
                box-shadow: none;
                background-color: rgba(0, 0, 0, 0.12);
            }
        }
        p {
            margin: 0;
        }
    }
}