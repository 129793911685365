.btn {
    border: none;
    padding: 5px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-orange {
        background-color: $prime-color;
    }
    &-submit {
        background-color: $prime-color;
    }
    &:hover {
        cursor: pointer;
        color: white;
        background-color: $hover-color;
    }
    &:disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.12);
    }
}